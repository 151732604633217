import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { TEXT_EDITOR, MULTILINE } from "../../../configs/textFieldSubTypes";

import styles from "./dataTypes.less";

const TextField = props => {
  let val = props.value;
  if (props.config.get("type") == MULTILINE || props.config.get("type") == TEXT_EDITOR) {
    if (val && val.length > 200) {
      val = val.substr(0, 200) + "...";
    }
  }

  return (
    <span className={cn(props.containerClassName, styles.simpleField)}>
      {val || " "}
    </span>
  );
};

TextField.propTypes = {
  config: PropTypes.object
};

export default TextField;
