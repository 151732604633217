import React from "react";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";

class Progress {
  static type = FIELD_TYPES.PROGRESS;
  static components = {
    inline: require("../../components/common/dataTypes/ProgressField").default,
    control: require("../../components/common/UI/ControlList/controls/Progress")
      .default
  };

  static getComponent = type => Progress.components[type];

  static parseValue = (value, field) => {
    value = _.trim(value);

    /* парс пустого значения */
    if (Progress.isEmpty(value)) {
      value = Progress.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    /* парс значения в число */
    let floatedValue = value.replaceAll(/,/g, ".");
    floatedValue = parseFloat(value);

    if (_.isNumber(floatedValue)) {
      floatedValue = Math.round(floatedValue);

      if (Progress.validateValue(floatedValue, field)) {
        return { value: floatedValue, status: VALUE_STATUSES.VALID };
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (value, field) => {
    if (_.isNumber(value)) {
      return value <= 100 && value >= 0;
    }
    return false;
  };

  static getEmptyValue = () => 0;

  static isEmpty = value => !value;

  static compare = (value1, value2) => {
    return value1 === value2;
  };

  static createComponent = (field, value, type) => {
    const Component = Progress.components[type];
    return ({ containerClassName }) => {
      if (Progress.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = () => {
    return undefined;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = () => {
    return;
  }

  static boardWitchColor = () => {
    return false;
  }

  static visibleRules = (value) => {
    let v = value;
    let newV = {};
    if (v.at || v.at === 0) {
      newV.$gte = parseFloat(v.at);
    }
    if (v.to || v.to === 0) {
      newV.$lte = parseFloat(v.to);
    }
    v = newV;
    
    return v;
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by value
    return records.sortBy(r => r.getIn(["values", fieldId]));
  }

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      default:
        return false
    }
  }
}

export default Progress;
