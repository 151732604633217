import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { matchPath } from "react-router";
import NavRoute from "../../common/router/Route";
import routes from "../../../routes";
import Section from "./Section";
// import CatalogEditor from "../../catalogEditor";
import AppHeader from "../AppHeader";
// import AuthPage from "../../authForms";
import styles from "./appBody.less";

import Loading from "../../common/Loading";

const AuthPage = lazy(() => import("../../authForms"))
const CatalogEditor = lazy(() => import("../../catalogEditor"))

const AppBody = () => {
  return (
    <div className={styles.container}>
      <Route
        render={props => {
          const { location } = props;
          const matchEdit = matchPath(location.pathname, {
            path: routes.catalogEdit.path,
            exact: false,
            strict: false
          });
          const matchAdd = matchPath(location.pathname, {
            path: routes.catalogAdd.path,
            exact: false,
            strict: false
          });

          const matchAuth = matchPath(location.pathname, {
            path: routes.auth.path,
            exact: false,
            strict: false
          });

          if (matchEdit) {
            return (
              <React.Fragment>
                <AppHeader />
                <NavRoute
                  route={routes.catalogEdit}
                  render={props => {
                    return (
                      <Suspense fallback={<Loading />}>
                        <CatalogEditor isStateEditCatalog={true} {...props} />
                      </Suspense>
                    );
                  }}
                />
              </React.Fragment>
            );
          } else if (matchAdd) {
            return (
              <React.Fragment>
                <AppHeader />
                <NavRoute
                  route={routes.catalogAdd}
                  render={_props => {
                    return <Suspense fallback={<Loading />}>
                      <CatalogEditor isStateAddCatalog={true} />;
                    </Suspense>
                  }}
                />
              </React.Fragment>
            );
          } else if (matchAuth) {
            return <Suspense fallback={<Loading />}>
              <AuthPage {...props} />;
            </Suspense>
          } else {
            return (
              <React.Fragment>
                <AppHeader />
                <NavRoute route={routes.section} component={Section} />
              </React.Fragment>
            );
          }
        }}
      />
    </div>
  );
};

export default AppBody;
