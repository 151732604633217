import _ from "lodash";
import React, { Component, lazy, Suspense } from "react";
import cn from "classnames";

import apiActions from "../../../../actions/apiActions";
import uiActions from "../../../../actions/uiActions";
import { connect } from "../../../StateProvider";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";

import styles from "./section.less";
import Loading from "../../../common/Loading";

const CatalogsMenu = lazy(() => import("./CatalogsMenu"));
const SectionHeader = lazy(() => import("./SectionHeader"));
const SectionBody = lazy(() => import("./SectionBody"));

class Section extends Component {
  componentDidMount() {
    const { sectionId } = this.props.match.params;

    if (sectionId) {
      apiActions.getSection({ sectionId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSectionId = nextProps.match.params.sectionId;

    if (newSectionId && newSectionId !== this.props.match.params.sectionId) {
      // update catalogs.
      apiActions.getSection({ sectionId: newSectionId });
    }
  }

  componentDidUpdate(prevProps) {
    this.showHelper();
    if (!_.isEqual(prevProps.location, this.props.location)) {
      uiActions.unsetHelperCode("sectionCatalogCreation");
    }
  }

  showHelper() {
    const { catalogs, match } = this.props;
    const { sectionId } = match.params;
    const currentCatalogs =
      catalogs &&
      catalogs.filter(catalog => catalog.get("sectionId") == sectionId);
    // Check if section loaded and no catalogs present
    if (
      catalogs &&
      catalogs.size &&
      currentCatalogs &&
      currentCatalogs.size === 0
    ) {
      uiActions.showHelper("sectionCatalogCreation");
    }
  }

  render() {
    const { sectionId } = this.props.match.params;
    const catalogs = this.props.catalogs;

    const section = this.props.sections.get(sectionId);
    // todo: check access for
    const isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      section,
      PRIVILEGE_CODES.ADMIN
    );
    return (
      <section className={styles.container}>
        <div
          className={cn(styles.sideBarContainer, {
            [styles.sideBarContainerFixed]: this.props.sidebarMenu.get("fixed")
          })}
        >
          <Suspense fallback={<Loading />}>
            <CatalogsMenu
              sectionId={sectionId}
              catalogs={catalogs}
              isAccessAdmin={isAccessAdmin}
              history={this.props.history}
              location={this.props.location}
            />
            <SectionHeader
              section={section}
              isAccessAdmin={isAccessAdmin}
              history={this.props.history}
            />
          </Suspense>
        </div>
        <Suspense fallback={<Loading />}>
          <SectionBody />
        </Suspense>
      </section>
    );
  }
}

export default connect(
  Section,
  {
    sections: ["sections"],
    catalogs: ["catalogs"],
    sidebarMenu: ["ui", "sidebarMenu"]
  }
);
