import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";
import PropTypes from "prop-types";
import cn from "classnames";

import Icon from '../Icon';

import styles from "./table.less";

export default class SortHeaderCell extends Component {
  static propTypes = {
    sorting: PropTypes.number,
    columnKey: PropTypes.string,
    onSortChange: PropTypes.func
  };

  state = {
    sorting: this.props.sorting
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sorting !== this.props.sorting) {
      this.setState({
        sorting: nextProps.sorting
      });
    }
  }

  onSortChange = e => {
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.state.sorting !== 0 ? -1 * this.state.sorting : 1
      );
    }
  };

  render() {
    const {
      onSortChange,
      children,
      sorting,
      sortHeaderCellClassName,
      ...props
    } = this.props;
    let iconProps;
    if (sorting) {
      sorting === 1
        ? (iconProps = {
            type: "icon arrows-chevron-medium-1-01"
          })
        : (iconProps = {
            type: "icon arrows-chevron-medium-2-01"
          });
    }

    let spanProps;
    if (sorting) {
      spanProps = {
        style: { maxWidth: this.props.width - 34 } // for icon
      };
    } else {
      spanProps = {
        style: { maxWidth: this.props.width }
      };
    }

    return (
      <Cell className={this.props.className} {...props}>
        <span
          onClick={this.onSortChange}
          {...spanProps}
          className={cn(sortHeaderCellClassName || styles.sortHeaderCell)}
        >
          {children}
        </span>
        {sorting ? (
          <span className={styles.headerIcon}>
            <Icon {...iconProps} />
          </span>
        ) : null}
      </Cell>
    );
  }
}
