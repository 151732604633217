import PropTypes from "prop-types";
import _ from "lodash";
import React, { Component } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import { matchPath } from "react-router";
import { withTranslation } from 'react-i18next';

import batchRecordsActions from "../../../../actions/batchRecordsActions";
import sceneActions from "../../../../actions/sceneActions";

import Prompt from "../../../common/router/Prompt";
import NavRedirect from "../../../common/router/Redirect";

import RecordsBatchUpdateHeader from "../batchUpdateHeader";
import RecordBody from "../../../Record/RecordBody";
import SCENE_CONTAINER from "../../../../configs/sceneContainer";

import { BATCH_RECORDS_STATUS } from "../../../../configs/recordsBatchStatus";
import routes from "../../../../routes";

import styles from "../../../Record/record.less";
import { connect } from "../../../StateProvider";
import valueActionTypes from "../../../../configs/valueActionTypes";

class RecordsBatchUpdateRecord extends Component {
  static propTypes = {
    onStartUpdate: PropTypes.func.isRequired,
    onPauseUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    catalogId: PropTypes.string.isRequired,
    batchUpdateRecord: ImmutablePropTypes.map.isRequired,
    loading: PropTypes.bool,
    isWebForm: PropTypes.bool
  };

  state = {
    fieldsEditableStatus: Immutable.Map({}),
    valueActions: Immutable.Map({})
  };

  componentDidUpdate() {
    const {
      isActive,
      recordHasBeenEdit,
      shouldClose,
      sceneId,
      updating
    } = this.props;

    /* в случаях когда смена урла непроизводится, данное условие позволяет удалить неизмененный компонент из очереди сцен */
    if (shouldClose && isActive && !(recordHasBeenEdit || updating)) {
      sceneActions.deleteScene(sceneId);
    }
  }

  changeFieldEditableStatus = fieldId => {
    const {
      catalogId,
      sceneId,
      batchUpdateRecord,
      readOnly,
      fields
    } = this.props;

    const field = fields.find(f => f.get("id") == fieldId);
    const recordId = batchUpdateRecord.get("id");
    const forbiddenToSelect = field.getIn("apiOnly") && field.get("readOnly");

    if (readOnly || forbiddenToSelect) {
      return;
    }

    /* стремный код, понятно что нужно данные хравнить не в стейте и что нужно видимость поля получать по пропсам */
    this.setState(
      ({ fieldsEditableStatus }) => {
        const status = fieldsEditableStatus.get(fieldId);
        fieldsEditableStatus = fieldsEditableStatus.set(fieldId, !status);
        batchRecordsActions.changefieldsEditableStatus(
          { sceneId, fieldId },
          !status
        );

        return {
          fieldsEditableStatus
        };
      },
      () => {
        const { fieldsEditableStatus } = this.state;

        let values = batchUpdateRecord.get("values");
        values = values && values["delete"](fieldId);

        const prevStatus = fieldsEditableStatus.get(fieldId);

        // обнуляем значения поля, при смене видимости на отрицательну.(звапятая стоит верно)
        if (!prevStatus) {
          batchRecordsActions.updateBatchUpdateRecordValues(
            catalogId,
            recordId,
            values
          );
        }
      }
    );
  };

  changeFieldValueActions = (action, fieldId) => {
    const { sceneId } = this.props;

    /* тут записываем в рекорд айди который батч апдейд по по айди филда его значение Добавить Убрать Изменить Удалить */
    this.setState(
      ({ valueActions }) => {
        valueActions = valueActions.set(fieldId, action);
        batchRecordsActions.changeBatchUpdateValueActions(
          { sceneId, fieldId },
          action
        );

        return {
          valueActions
        };
      }
    );
  };

  onSaveField = (params = {}) => {
    const { catalogId, recordId, fieldId, data } = params;

    const { sceneId } = this.props;

    batchRecordsActions.changeBatchStatus(sceneId);
  };

  onPlaceHolderClick = fieldId => {
    this.changeFieldEditableStatus(fieldId);
  };

  render() {
    const {
      catalog,
      catalogId,
      scene,
      sceneId,

      batchUpdateRecord,
      recordHasBeenEdit,
      updating,
      isActive,
      shouldClose,
      readOnly,
      loading,

      onClose,
      onStartUpdate,
      onPauseUpdate,
      onResumeUpdate,
      isWebForm,
      t
    } = this.props;

    const { fieldsEditableStatus } = this.state;

    const placeHolder = t("batchUpdateRecords.form.fields.placeholder");
    const notChangeTitle = t("batchUpdateRecords.form.fields.notChange");

    // if (loading) {
    //   return <div className={styles.container}>
    //     <Loading fullHeight={true} />
    //   </div>
    // }

    return (
      <div className={styles.container}>
        {/* to show promp && redirect to all records for rightPanel */}
        {shouldClose && isActive ? (
          <NavRedirect route={routes.records} />
        ) : null}

        <Prompt
          when={recordHasBeenEdit || updating}
          message={location => {
            const match = matchPath(location.pathname, {
              path: routes.batchUpdate.path,
              exact: false,
              strict: false
            });

            if (match) {
              return true;
            } else if (recordHasBeenEdit || updating) {
              return this.props.sceneId;
            }
          }}
        />

        <RecordsBatchUpdateHeader
          catalog={catalog}
          scene={scene}
          hasBeenEdit={recordHasBeenEdit}
          batchUpdateRecord={batchUpdateRecord}
          fieldsEditableStatus={fieldsEditableStatus}
          // specialPrivileges={record.get("fieldPrivilegeCodes")}
          onStartUpdate={onStartUpdate}
          onPauseUpdate={onPauseUpdate}
          onResumeUpdate={onResumeUpdate}
          isWebForm={isWebForm}
          readOnly={readOnly}
          onClose={onClose}
          isLoading={loading}
        />

        <RecordBody
          sceneId={sceneId}
          catalogId={catalogId}
          onEndEditing={this.props.onEndEditing}
          record={batchUpdateRecord}
          fieldsEditableStatus={fieldsEditableStatus}
          readOnly={readOnly}
          isWebForm={isWebForm}
          placeHolder={placeHolder}
          notChangeTitle={notChangeTitle}
          changeFieldEditableStatus={this.changeFieldEditableStatus}
          changeFieldValueActions={this.changeFieldValueActions}
          onSaveField={this.onSaveField}
          onPlaceHolderClick={this.onPlaceHolderClick}
        />
      </div>
    );
  }
}

export default withTranslation()(connect(
  RecordsBatchUpdateRecord,
  {
    modal: ["modal"]
  },
  (props, { modal }) => {
    const { batchUpdateRecord, scene, sceneId } = props;

    const recordHasBeenEdit = batchUpdateRecord.get("hasBeenEdit") || false;
    const updatingStatus = scene.getIn(["data", "batchStatus"]);

    const updating =
      updatingStatus === BATCH_RECORDS_STATUS.UPDATING ||
      updatingStatus === BATCH_RECORDS_STATUS.PAUSED;
    const readOnly = updatingStatus !== BATCH_RECORDS_STATUS.READY;
    const loading = updatingStatus === BATCH_RECORDS_STATUS.LOADING;

    const sceneExist = !!scene;
    const shouldClose = sceneExist && scene.get("shouldClose");

    const fields = props.catalog.get("fields");

    const isActive =
      // WINDOW scenes are always active
      (sceneExist && scene.get("container") == SCENE_CONTAINER.WINDOW) ||
      // only one POPUP scene can be active
      (modal && modal.get("activeScene") === sceneId);

    return {
      ...props,
      fields,
      recordHasBeenEdit,
      updating,
      loading,
      isActive,
      shouldClose,
      readOnly
    };
  }
));
