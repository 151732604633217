import React, { Component } from "react";
import { Row, Col, Input, Select, Button } from "antd";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { Switch, Route, withRouter } from "react-router";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import {
  AppstoreOutlined,
  CalendarOutlined,
  FundOutlined,
  HistoryOutlined,
  TableOutlined
} from "@ant-design/icons";
import cn from "classnames";

import ButtonClose from "../../../../../../../common/UI/ButtonClose";
import DefaultRedirect from "../../../../../../../common/router/DefaultRedirect";
import TabsMenu from "../../../../../../../common/menu/TabsMenu";
import NavRoute from "../../../../../../../common/router/Route";
import NavRedirect from "../../../../../../../common/router/Redirect";
import StateRedirect from "../../../../../../../common/router/StateRedirect";
import routes from "../../../../../../../../routes";
import RecordsActivities from "../../../../../../../Records/RecordsActivities";
import PopoverFilter from "./filterPopover";
import HistoryUserFilter from "../../../../../../../History/HistoryUserFilter";
import FieldFilter from "../../../../../../../History/FieldFilter";
import filterActions from "../../../../../../../../actions/filterActions";
import userSettingsActions from "../../../../../../../../actions/userSettingsActions";
import { connect } from "../../../../../../../StateProvider";
import ViewSelector from "./ViewSelector";
import UrlParamSync from "../../../../../../../UrlParamSync";

import styles from "./viewHeader.less";
import HistoryActionFilter from "../../../../../../../History/HistoryActionFilter";
import BoardViewMode from "../../../../../../../Reports/Header/BoardViewMode";

const Option = Select.Option;

class ViewHeader extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    viewId: PropTypes.string,
    isWebForm: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: this.searchText(),
      expandedQuickSearch: false // Состояние, описывающее, раскрыт ли быстрый поиск
    };
  }

  getDefaultRoute(tabs) {
    let defaultTab;

    let defaultViewMode = this.props.defaultViewMode;
    defaultViewMode = defaultViewMode && defaultViewMode.get("value");
    if (defaultViewMode) {
      defaultTab = tabs.find(t => t.get("id") === defaultViewMode);
    }
    defaultTab = defaultTab ? defaultTab : tabs.getIn([0]);

    return defaultTab.get("route");
  }

  setDefaultRoute(tab) {
    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: undefined,
      option: "defaultViewMode",
      value: tab.get("id")
    });
  }

  searchText(props = this.props) {
    return props.scene ? props.scene.get("searchText") : "";
  }

  handleInputFocus = e => {
    // функция, изменяющая состояние
    this.setState(() => ({ expandedQuickSearch: true }));
  };

  handleInputBlur = () => {
    const text = this.state.searchText;
    const hasText = text || text === "0";
    this.setState(() => ({
      expandedQuickSearch: hasText
    }));
  };

  onChangeSearch(text, viewMode) {
    this.setState(
      {
        searchText: text
      },
      () => {
        filterActions.searchByText(
          this.props.sceneId,
          text,
          {
            viewId: this.props.viewId
          },
          viewMode
        );
      }
    );
  }

  onTableChangeSearch = e => {
    const text = e.target.value;
    this.onChangeSearch(text, "table");
  };
  onTableChangeSearchFromURL = text => {
    this.onChangeSearch(text, "table");
  };

  onCardsChangeSearch = e => {
    const text = e.target.value;
    this.onChangeSearch(text, "cards");
  };
  onCardsChangeSearchFromURL = text => {
    this.onChangeSearch(text, "cards");
  };

  UNSAFE_componentWillReceiveProps (nextProps) {
    const newSearchText = this.searchText(nextProps);
    const searchText = this.searchText();
    if (this.state.searchText) {
      this.setState({ expandedQuickSearch: true });
    }

    if (newSearchText !== searchText) {
      this.setState({ searchText: newSearchText });
    }
  }

  onClose = () => {
    this.props.onClose && this.props.onClose(this.props.sceneId);
  };

  getCountRecord = () => {
    return this.props.scene && this.props.scene.get("recordsCount");
  };

  getTabsItems = () => {
    const countRecords = this.getCountRecord();
    const { calendarExists, t } = this.props;

    let tabs = Immutable.List([
      Immutable.Map({
        id: "table",
        icon: <TableOutlined />,
        title: t("catalogData.types.table"),
        nameActive: `${t("catalogData.types.table")} ${countRecords || ""}`,
        route: routes.records
      }),
      Immutable.Map({
        id: "cards",
        icon: <AppstoreOutlined />,
        title: t("catalogData.types.cards"),
        nameActive: `${t("catalogData.types.cards")} ${countRecords || ""}`,
        route: routes.cards
      })
    ]);

    if (calendarExists) {
      tabs = tabs.concat(
        Immutable.List([
          Immutable.Map({
            id: "calendar",
            icon: <CalendarOutlined style={{ verticalAlign: "-2px" }} />,
            title: t("catalogData.types.calendar"),
            nameActive: `${t("catalogData.types.calendar")} ${countRecords ||
              ""}`,
            route: routes.calendar
          })
        ])
      );
    }

    tabs = tabs.concat(
      Immutable.List([
        Immutable.Map({
          id: "reports",
          icon: <FundOutlined />,
          title: t("catalogData.types.reports"),
          nameActive: t("catalogData.types.reports"),
          route: routes.reports
        }),
        Immutable.Map({
          id: "statistics",
          icon: (
            <span
              style={{
                position: "relative",
                top: "-2px"
              }}
            >
              Σ
            </span>
          ),
          title: t("catalogData.types.statistics"),
          nameActive: t("catalogData.types.statistics"),
          route: routes.statistics
        }),
        Immutable.Map({
          id: "history",
          icon: <HistoryOutlined />,
          title: t("catalogData.types.history"),
          nameActive: t("catalogData.types.history"),
          route: routes.history
        })
      ])
    );

    return tabs;
  };

  getDefaultVeiw() {
    let { defaultViewId, match } = this.props;

    defaultViewId = defaultViewId && defaultViewId.get("value");
    const matchViewId = _.get(match, ["params", "viewId"]);

    if (matchViewId) {
      return Immutable.Map({ id: matchViewId });
    }

    if (defaultViewId) {
      return Immutable.Map({ id: defaultViewId });
    }

    return Immutable.Map({ id: "0" });
  }

  render() {
    const {
      catalog,
      sceneId,
      scene,
      filter,
      viewId,
      visibleFields,
      views,
      isNewViewExist,
      openRecordsInModal,
      leftPanelVisible,
      isWebForm,
      t
    } = this.props;
    // const views = catalog && catalog.get("views");
    const fields = catalog && catalog.get("fields");
    const defaultView = this.getDefaultVeiw();
    const tabs = this.getTabsItems();

    const quickSearch = (
      <Col
        className={cn(styles.quickSearchContainer, {
          [styles.collapsed]: !this.state.expandedQuickSearch
        })}
      >
        <Input
          allowClear
          placeholder={
            this.state.expandedQuickSearch
              ? this.props.t("catalogData.expandedQuickSearch")
              : this.props.t("catalogData.quickSearch")
          }
          className={styles.quickSearchInput}
          value={this.state.searchText}
          onChange={this.onTableChangeSearch}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
        />
        <UrlParamSync
          name="search"
          value={this.state.searchText}
          onChange={this.onCardsChangeSearchFromURL}
        />
      </Col>
    );

    return (
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className={styles.container}
      >
        {/* редирект на новый вид */}
        {isNewViewExist && !(viewId === "$new") ? (
          <StateRedirect route={routes.view} params={{ viewId: "$new" }} />
        ) : null}

        <NavRoute
          route={routes.view}
          exact
          render={props => {
            /* добавил viewId в качестве параметра */
            return (
              <NavRedirect
                route={this.getDefaultRoute(tabs, {
                  viewId: this.props.viewId
                })}
              />
            );
          }}
        />
        <Row
          type="flex"
          align="middle"
          className={styles.containerForTabsNViews}
        >
          <NavRoute route={routes.record}>
            {props => {
              if (props.match || openRecordsInModal || !leftPanelVisible) {
                return (
                  <React.Fragment>
                    <DefaultRedirect
                      route={routes.view}
                      params="viewId"
                      object={defaultView}
                    />

                    {
                      <NavRoute route={routes.view}>
                        {({ match }) => {
                          if (
                            this.props.viewsLoaded &&
                            this.props.views &&
                            !this.props.views.find(
                              view =>
                                view.get("id") ===
                                _.get(match, ["params", "viewId"])
                            )
                          ) {
                            return (
                              <StateRedirect
                                route={routes.view}
                                params={{
                                  viewId: defaultView && defaultView.get("id")
                                }}
                              />
                            );
                          }
                          return null;
                        }}
                      </NavRoute>
                    }
                    <ViewSelector
                      viewId={viewId}
                      views={views}
                      history={this.props.history}
                      location={this.props.location}
                    />
                    <PopoverFilter
                      catalog={catalog}
                      sceneId={sceneId}
                      viewId={viewId}
                    />
                  </React.Fragment>
                );
              }
              return null;
            }}
          </NavRoute>
          <NavRoute route={routes.record}>
            {props => {
              return (
                !props.match && (
                  <TabsMenu
                    items={tabs}
                    className={styles.tabsMenu}
                    onClick={_.bind(this.setDefaultRoute, this)}
                  />
                )
              );
            }}
          </NavRoute>
        </Row>

        <NavRoute route={routes.records} render={props => quickSearch} />
        <NavRoute route={routes.cards} render={props => quickSearch} />
        <NavRoute route={routes.calendar} render={props => quickSearch} />
        <NavRoute route={routes.reports} render={props => quickSearch} />
        <NavRoute route={routes.statistics} render={props => quickSearch} />

        <NavRoute
          route={routes.reports}
          render={props => {
            return (
              <BoardViewMode
                toggleColsWidthMode={this.props.toggleColsWidthMode}
                colsMode={this.props.colsMode}
                scene={scene}
                sceneId={sceneId}
                {...props}
              />
            );
          }}
        />
        <Col>
          {catalog && (
            <Switch>
              <Route
                path={routes.records.path}
                render={props => {
                  return (
                    <RecordsActivities
                      catalog={catalog}
                      sceneId={sceneId}
                      viewId={viewId}
                      viewMode="table"
                      onClose={this.onClose}
                      withCross={openRecordsInModal && !isWebForm}
                      isWebForm={isWebForm}
                      openRecordsInModal={openRecordsInModal}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                path={routes.cards.path}
                render={props => {
                  return (
                    <RecordsActivities
                      catalog={catalog}
                      sceneId={sceneId}
                      viewId={viewId}
                      viewMode="cards"
                      onClose={this.onClose}
                      withCross={openRecordsInModal && !isWebForm}
                      isWebForm={isWebForm}
                      openRecordsInModal={openRecordsInModal}
                      createInPopup={true}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                path={routes.statistics.path}
                render={props => {
                  return (
                    <RecordsActivities
                      catalog={catalog}
                      sceneId={sceneId}
                      viewId={viewId}
                      viewMode="statistics"
                      onClose={this.onClose}
                      withCross={openRecordsInModal && !isWebForm}
                      isWebForm={isWebForm}
                      openRecordsInModal={openRecordsInModal}
                      createInPopup={true}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                path={routes.calendar.path}
                render={props => (
                  <div style={{ display: "flex" }}>
                    <RecordsActivities
                      catalog={catalog}
                      sceneId={sceneId}
                      viewId={viewId}
                      viewMode="calendar"
                      onClose={this.onClose}
                      withCross={openRecordsInModal && !isWebForm}
                      isWebForm={isWebForm}
                      openRecordsInModal={openRecordsInModal}
                      createInPopup={true}
                      {...props}
                    />
                  </div>
                )}
              />
              <Route
                path={routes.history.path}
                render={props => {
                  return (
                    <div className={styles.historyFilterContainer}>
                      <HistoryActionFilter
                        filter={filter}
                        catalogId={this.props.catalogId}
                        sceneId={this.props.sceneId}
                        t={t}
                      />
                      <HistoryUserFilter
                        filter={filter}
                        catalogId={this.props.catalogId}
                        sceneId={this.props.sceneId}
                      />
                      <FieldFilter
                        getFieldsToRender={this.props.getFieldsToRender}
                        filter={filter}
                        visibleFields={visibleFields}
                        sceneId={sceneId}
                        catalogId={this.props.catalogId}
                        fields={fields}
                        viewMode="catalogHistory"
                        withCross={openRecordsInModal}
                      />

                      {openRecordsInModal && (
                        <ButtonClose onClick={this.onClose} shiftRight />
                      )}
                    </div>
                  );
                }}
              />
              <Route
                path={routes.reports.path}
                render={props => (
                  <div className={styles.widgetsActivities}>
                    {openRecordsInModal && (
                      <ButtonClose onClick={this.onClose} shiftRight />
                    )}
                  </div>
                )}
              />
            </Switch>
          )}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(ViewHeader),
    {
      userSettings: ["userSettings", "catalogs"],
      catalogsHistory: ["catalogsHistory"],
      scenes: ["scenes"],
      catalogs: ["catalogs"],
      leftPanelVisible: ["ui", "leftPanel", "visible"]
    },
    (
      props,
      { userSettings, catalogsHistory, scenes, catalogs, leftPanelVisible }
    ) => {
      const scene = scenes.get(props.sceneId);
      const catalog = catalogs.get(props.catalogId);
      const viewId = props.viewId;
      const views =
        catalog &&
        catalog.get("views") &&
        catalog.get("views").merge(scene && scene.get("views"));
      const viewsLoaded = catalog && catalog.get("viewsLoaded");

      const filtersChanged =
        (scene && scene.getIn(["views", viewId, "filtersChanged"])) || false;
      const filter = catalogsHistory.getIn([props.catalogId, "filter"]);

      const defaultViewId = userSettings.getIn([
        props.catalogId,
        "options",
        "defaultViewId"
      ]);

      const isNewViewExist =
        views && views.some(view => view.get("id") === "$new");
      return {
        filter,
        scene,
        catalog,
        views,
        viewsLoaded,
        filtersChanged,
        isNewViewExist,
        leftPanelVisible,
        defaultViewId,
        ...props
      };
    }
  )
);
