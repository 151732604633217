import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Suspense
} from "react";
import _ from "lodash";
import cn from "classnames";
import { Input, Select, InputNumber } from "antd";
import MaskedInput from "react-input-mask";

import Icon from "../../../Icon";
import LoadingSpin from "../../../../LoadingSpin";
import maskIsValid from "../../../../../Record/maskValidator";
import { formatCharsInput } from "../../../../../../configs/maskFormatCharacters";

import styles from "../controls.less";

const InputEditor = React.lazy(() => import("./textEditor/InputEditor"));
const CodeEditor = React.lazy(() => import("../CodeEditor"));
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const TextInputWithActions = props => {
  const {
    wrapperClassName,
    className,
    style,
    actionsClassName,
    inputWrapperClassName,
    actions,
    type,
    theme,
    multiline,
    script,
    minRows = 1,
    maxRows = 20,
    config,
    onEndEditing, // just to exclude from props passed to Inputs
    allowTabs, // just to exclude from props passed to Inputs
    subType, // just to exclude from props passed to Inputs
    prepareNumber, // just to exclude from props passed to Inputs
    textEditor,
    fieldId,
    t,
    ...otherProps
  } = props;

  let { mask, options, ...restProps } = otherProps;

  mask = mask && maskIsValid(mask) ? mask : undefined;
  const [actionsWidth, setActionsWidth] = useState(0);
  const [value, setValue] = useState(props.value);
  const [oldValue, setOldValue] = useState(type === "number" ? null : "");

  const inputRef = useRef(null);
  const actionsNodeRef = useRef(null);
  const changeTimerRef = useRef(null);
  const valueRef = useRef(props.value);

  useEffect(
    () => {
      valueRef.current = props.value;
      setValue(props.value);
    },
    [props.value]
  );

  useEffect(() => {
    recalcActionsWidth();
    setFocus();
    if (oldValue === "" || oldValue === null) {
      setTimeout(() => {
        valueRef.current && setOldValue(valueRef.current);
      }, 200);
    }
  }, []);

  const recalcActionsWidth = () => {
    if (actionsNodeRef.current) {
      const newActionsWidth = actionsNodeRef.current.clientWidth;
      if (newActionsWidth !== actionsWidth) {
        setActionsWidth(newActionsWidth);
      }
    }
  };

  const setFocus = () => {
    if (props.autoFocus && !props.textEditor) {
      inputRef.current.focus();
    }
  };

  const onChangeDebounce = useCallback(
    newValue => {
      clearTimeout(changeTimerRef.current);
      changeTimerRef.current = setTimeout(() => {
        props.onChange && props.onChange(newValue);
      }, 200);
    },
    [props.onChange]
  );

  const onChangeDebounceCancel = useCallback(() => {
    clearTimeout(changeTimerRef.current);
  }, []);

  const onChange = e => {
    const newValue = e?.target ? e.target.value : e;
    setValue(newValue);
    onChangeDebounce(newValue);
  };

  const onBlurSelect = e => {
    if (props.readOnly) return;
    setBlur(value);
  };

  const onBlur = e => {
    if (props.readOnly) return;
    let value = e?.target ? e.target.value : e;
    if (type === "number") {
      value = prepareNumber ? prepareNumber(value) : value;
    }
    setBlur(value);
  };

  const setBlur = newValue => {
    setValue(newValue);
    onChangeDebounceCancel();
    if (newValue !== oldValue) {
      props.onChange && props.onChange(newValue);
      props.onEndEditing && props.onEndEditing(newValue);
    }
    props.onBlur && props.onBlur(newValue);
    setOldValue(newValue);
  };

  const onKeyDown = e => {
    props.onKeyDown && props.onKeyDown(e);

    if (!props.allowTabs) return;

    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      document.execCommand("insertText", false, "\t");
      return false;
    }
  };

  const setDebounceValue = value => {
    setValue(value);
    onChangeDebounce(value);
  };

  const renderSelectOption = useCallback(o => {
    const value = o.get("value");
    const label = o.get("label");
    const subLabel = o.get("subLabel");
    const icon = o.get("icon");
    return (
      <Option value={value} label={label}>
        {icon && (
          <Icon className={styles.optionIcon} type={"icon " + subLabel} />
        )}
        {label}
        {subLabel && <span className={styles.optionSubLabel}>{subLabel}</span>}
      </Option>
    );
  }, []);

  const getPlaceHolderMask = useCallback(mask => {
    const charsEditableMask = _.keys(formatCharsInput).join("");
    let placeholder = "";
    let shielding = false;

    for (let i = 0; i < mask.length; i++) {
      if (shielding) {
        shielding = false;
        placeholder += mask[i];
        continue;
      }

      if (mask[i] == "\\") {
        shielding = true;
        continue;
      }

      if (charsEditableMask.includes(mask[i])) {
        placeholder += "_";
        continue;
      }

      placeholder += mask[i];
    }

    return placeholder;
  }, []);

  const displayValue = useMemo(
    () => {
      return value || value === 0 ? value : "";
    },
    [value]
  );

  const inputCN = cn(className, {
    [styles.inputReadOnly]: props.readOnly,
    [styles[theme]]: !!theme,
    [styles.readOnly]: props.readOnly
  });

  let inputStyle = _.assign({}, style);
  const actionsStyle = {};
  const containerCN = cn(wrapperClassName, {
    [styles.textInputContainer]: type !== "number",
    [styles.inputMask]: !multiline && !!mask
  });

  if (actions && actions.length > 0) {
    inputStyle.paddingRight = actionsWidth;
  } else {
    actionsStyle.visibility = "hidden";
  }

  let control;

  if (type === "number") {
    control = props.readOnly ? (
      <span className={inputCN}>
        {props.formatter && props.formatter(displayValue)}
      </span>
    ) : (
      <InputNumber
        ref={inputRef}
        onKeyDown={onKeyDown}
        className={inputCN}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
      />
    );
  } else if (mask) {
    control = (
      <MaskedInput
        formatChars={formatCharsInput}
        mask={mask}
        {...restProps}
        placeholder={getPlaceHolderMask(mask)}
        value={displayValue}
        style={inputStyle}
        className={inputCN}
        onChange={onChange}
        onBlur={onBlur}
        disabled={props.readOnly}
      >
        {inputProps => <Input {...inputProps} ref={inputRef} />}
      </MaskedInput>
    );
  } else if (script) {
    control = (
      <Suspense fallback={<LoadingSpin spin={true} />}>
        <CodeEditor
          ref={inputRef}
          {...restProps}
          value={displayValue}
          style={inputStyle}
          className={inputCN}
          onChange={setDebounceValue}
          onBlur={setBlur}
          subType={subType}
          rows={config.get("rows")}
        />
      </Suspense>
    );
  } else if (options) {
    control = (
      <Select
        ref={inputRef}
        {...restProps}
        className={inputCN}
        style={inputStyle}
        value={displayValue}
        onChange={setDebounceValue}
        onBlur={onBlurSelect}
        onInputKeyDown={onKeyDown}
        showSearch={true}
        bordered={false}
        showArrow={false}
        dropdownMatchSelectWidth={300}
        filterOption={(input, option) =>
          (option.label || "").toLowerCase().includes(input.toLowerCase())
        }
      >
        {options.map(o => {
          if (_.isArray(o.options)) {
            return (
              <OptGroup key={o.value} label={o.label}>
                {o.options.map(o => {
                  return renderSelectOption(o);
                })}
              </OptGroup>
            );
          } else {
            return renderSelectOption(o);
          }
        })}
      </Select>
    );
  } else if (multiline) {
    control = (
      <TextArea
        ref={inputRef}
        {...restProps}
        value={displayValue}
        spellCheck="false"
        autoSize={{
          minRows: props.readOnly ? 1 : minRows,
          maxRows: maxRows
        }}
        style={_.assign(inputStyle, {
          resize: "none",
          minHeight: 29
        })}
        className={cn(inputCN, styles.textArea)}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    );
  } else if (textEditor) {
    control = (
      <Suspense fallback={<LoadingSpin spin={true} />}>
        <InputEditor
          ref={inputRef}
          {...restProps}
          value={displayValue}
          style={_.assign(inputStyle, {
            resize: "none",
            minHeight: 29
          })}
          className={cn(inputCN, styles.textArea)}
          onChange={onChange}
          onBlur={onBlur}
          fieldId={fieldId}
        />
      </Suspense>
    );
  } else if (props.children) {
    control = (
      <div style={inputStyle} className={cn("ant-input", inputCN)}>
        {props.children}
      </div>
    );
  } else {
    control = (
      <Input
        ref={inputRef}
        {...restProps}
        config={config}
        value={displayValue}
        style={inputStyle}
        className={inputCN}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    );
  }

  return (
    <div className={containerCN}>
      {control}
      {actions &&
        actions.length > 0 && (
          <ul
            className={cn(actionsClassName, styles.inputWithActions)}
            ref={actionsNodeRef}
            style={actionsStyle}
          >
            {actions.map((node, i) => (
              <li key={i}>{node}</li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default TextInputWithActions;
