import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Switch from "../UI/ControlList/controls/Switch";

import styles from "./dataTypes.less";

const SwitchField = props => {

  return (
    <div className={cn(props.containerClassName, styles.switchField)}>
      <Switch 
        {...props}
        editable={false}
      />
    </div>
  );
};

SwitchField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object
};

export default SwitchField;