import React from "react";
import PropTypes from "prop-types";

import { TEXT_EDITOR, MULTILINE, SCRIPT } from "../../../../../configs/textFieldSubTypes";

import TextInput from "./common/TextInput";

const TextControl = ({
  onEndEditing,
  eventable,
  updateProcess,
  editable,
  config,
  value,
  htmlId,
  onChange,
  onBlur,
  autoFocus,
  onKeyPress,
  style,
  wrapperClassName,
  className,
  id, 
  params
}) => {

  const isMultiLine = config.get("type") === MULTILINE;
  const istextEditor = config.get("type") === TEXT_EDITOR;
  const isScript = config.get("type") === SCRIPT;

  return (
    <TextInput
      id={htmlId}
      fieldId={id}
      type="text"
      wrapperClassName={wrapperClassName}
      className={className}
      style={style}
      value={value}
      theme={config.get("theme")}
      onChange={onChange}
      onBlur={onBlur}
      onEndEditing={onEndEditing}
      readOnly={!editable}
      autoFocus={autoFocus}
      multiline={isMultiLine}
      allowTabs={isMultiLine}
      textEditor={istextEditor}
      script={isScript}
      mask={config.get("mask")}
      eventable={eventable}
      updateProcess={updateProcess}
      minRows={isMultiLine && 2}
      placeholder={config.get("placeholder")}
      subType={config.get("subType")}
      onKeyPress={onKeyPress}
      config={config}
      params={params}
    />
  );
};

TextControl.propTypes = {
  controlConfig: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  eventable: PropTypes.bool,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  updateProcess: PropTypes.object,
  error: PropTypes.string,
  placeholder: PropTypes.string
};

export default TextControl;
