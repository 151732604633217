import React, { Component } from "react";
import { Row } from "antd";
import PropTypes from "prop-types";

import HandleBar from "./HandleBar";

const prefix = "splitterPane.";

class SplitterPane extends Component {
  static propTypes = {
    componentName: PropTypes.string,
    transparent: PropTypes.bool,
    defaultWidth: PropTypes.number,
    minWidth: PropTypes.string
  };

  static defaultProps = {
    defaultWidth: 40,
    minWidth: "30%",
    maxWidth: "72.5%"
  };

  state = {
    width: this.getWidth()
  };

  refSplitterPanel = React.createRef(null);

  saveWidth() {
    this.props.componentName &&
      (localStorage[prefix + this.props.componentName] = this.state.width);
  }

  getWidth() {
    return (
      localStorage[prefix + this.props.componentName] || this.props.defaultWidth
    );
  }

  handleMouseDown = e => {
    if (e.button === 2) {
      return;
    }

    this.initialRectSplitter = 
      this.refSplitterPanel.current.getBoundingClientRect();

    
    this.parentNode = 
      this.refSplitterPanel.current.parentNode.getBoundingClientRect();

    let clientX;

    if (e.type === "mousedown") {
      clientX = e.clientX;
    } else if (e.type === "touchstart") {
      clientX = e.touches[0].clientX;
    }

    this.clientX = clientX;

    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("touchmove", this.handleMouseMove);
    document.body.classList.add("no-select");
  };

  componentDidMount = () => {
    document.addEventListener("mouseup", this.handleMouseUp, true);
    document.addEventListener("touchend", this.handleMouseUp, true);
  };

  handleMouseMove = e => {
    let movingX;

    if (e.type === "mousemove") {
      movingX = e.clientX;
    } else if (e.type === "touchmove") {
      movingX = e.touches[0].clientX;
    }

    let deltaX = this.clientX - movingX;
    let width = deltaX + this.initialRectSplitter.width;
    width = (width / this.parentNode.width) * 100; // Value in percantage

    this.setState({
      width
    });
  };

  handleMouseUp = e => {
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("touchmove", this.handleMouseMove);
    document.body.classList.remove("no-select");
    this.saveWidth();
  };

  render() {
    const { width } = this.state;
    return (
      <Row
        type="flex"
        style={{
          width: width + "%",
          minWidth: this.props.minWidth,
          maxWidth: this.props.maxWidth,
          overflow: "hidden",
          height: "100%"
        }}
        ref={this.refSplitterPanel}
      >
        <HandleBar
          transparent={this.props.transparent}
          handleMouseDown={this.handleMouseDown}
        />
        {this.props.children}
      </Row>
    );
  }
}

export default SplitterPane;
